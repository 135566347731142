import React, { useState, useEffect } from 'react';
import { Link,useNavigate  } from 'react-router-dom';

interface BlogPost {
  id: number;
  title: string;
  author: string;
  date: string;
  media: string;
  content: string;
}

const HomeBlogs: React.FC = () => {
    const [blogs, setBlogs] = useState<BlogPost[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();
  
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://api.treaz.in/articles/all');
        if (!response.ok) {
          throw new Error('Failed to fetch blogs');
        }
        const data = await response.json();
        setBlogs(data.slice(0, 3)); // Only take first 3 blogs for homepage
      } catch (err) {
        setError('Failed to load blogs');
        console.error('Error fetching blogs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-48">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-red-500 p-4">
        {error}
      </div>
    );
  }

  const handleReadMore = (id: number) => {
    navigate(`/blog/${id}`);
  };


  return (
    <section id="blogs" className="py-8">
      <div className="container mx-auto md:px-44 px-0">
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-xl md:text-4xl font-MontBold text-white">Latest Blogs</h2>
          <Link 
            to="/blog" 
            className="bg-green-600 hover:bg-green-700 text-white font-bold md:py-2 py-1 px-4 rounded transition duration-300"
          >
            View All
          </Link>
        </div>
        <div className="flex overflow-x-auto pb-4 md:grid md:grid-cols-3 gap-6">
          {blogs.map((blog) => (
            <div 
              key={blog.id} 
              className="flex-shrink-0 w-72 md:w-auto bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="relative h-48">
                <img 
                  src={blog.media} 
                  alt={blog.title} 
                  className="w-full h-full object-cover"
                />
                <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black to-transparent p-4">
                  <p className="text-white text-sm">{new Date(blog.date).toLocaleDateString()}</p>
                </div>
              </div>
              <div className="p-4">
                <h3 className="text-lg font-MontSemibold mb-2 line-clamp-2">{blog.title}</h3>
                <p className="text-gray-600 text-sm mb-2">By {blog.author}</p>
                <button 
                  onClick={() => handleReadMore(blog.id)}
                  className="text-green-400 hover:text-green-300 font-semibold flex items-center group"
                >
                  Read More 
                  <svg className="w-4 h-4 ml-2 group-hover:translate-x-2 transition-transform" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HomeBlogs;