import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { 
  Search, 
  ArrowRight, 
  User, 
  Calendar,
  ChevronDown,
  ChevronUp 
} from 'lucide-react';

interface BlogPost {
  id: number;
  title: string;
  author: string;
  date: string;
  media: string;
  content: string;
  category: string;
}

const BlogSkeleton = () => (
  <div className="bg-slate-800 rounded-2xl animate-pulse">
    <div className="h-64 bg-slate-700 rounded-t-2xl"></div>
    <div className="p-6 space-y-4">
      <div className="h-4 bg-slate-700 rounded w-3/4"></div>
      <div className="h-4 bg-slate-700 rounded w-1/2"></div>
    </div>
  </div>
);

const Blogs: React.FC = () => {
  const [blogs, setBlogs] = useState<BlogPost[]>([]);
  const [filteredBlogs, setFilteredBlogs] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [expandedBlog, setExpandedBlog] = useState<number | null>(null);
  const [categories, setCategories] = useState<string[]>(['All']);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchBlogs = async () => {
      try {
        const response = await fetch('https://api.treaz.in/articles/all');
        const data: BlogPost[] = await response.json(); // Explicitly type the response
        
        setBlogs(data);
        setFilteredBlogs(data);
        
        // Extract unique categories from data
        const uniqueCategories = Array.from(new Set(data.map((blog: BlogPost) => blog.category)));
        setCategories(['All', ...uniqueCategories as string[]]); // Explicitly cast to string[]
      } catch (err) {
        console.error('Blogs fetch error', err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchBlogs();
  }, []);

  useEffect(() => {
    let result = blogs;

    if (searchTerm) {
      result = result.filter(blog => 
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.author.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (selectedCategory !== 'All') {
      result = result.filter(blog => blog.category === selectedCategory);
    }

    setFilteredBlogs(result);
  }, [searchTerm, selectedCategory, blogs]);

  const handleReadMore = (id: number) => {
    navigate(`/blog/${id}`);
  };

  const toggleExpand = (id: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setExpandedBlog(expandedBlog === id ? null : id);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 to-slate-800 text-white">
      <div className="container mx-auto px-4 py-16 max-w-7xl">
        <header className="text-center mb-12">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-emerald-400 to-blue-500">
            Discover Inspiring Stories
          </h1>
          <p className="text-slate-300 max-w-2xl mx-auto">
            Explore a world of insights, innovation, and inspiration across diverse topics.
          </p>
        </header>

        <div className="mb-12 flex flex-col md:flex-row gap-4 items-center justify-between">
          <div className="relative w-full max-w-md">
            <input 
              type="text" 
              placeholder="Search blogs..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-full bg-slate-800 border border-slate-700 focus:ring-2 focus:ring-emerald-500"
            />
            <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-slate-400" />
          </div>

          <div className="flex flex-wrap justify-center gap-2">
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm transition ${
                  selectedCategory === category 
                    ? 'bg-emerald-500 text-white' 
                    : 'bg-slate-800 text-slate-300 hover:bg-slate-700'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {loading ? (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3, 4, 5, 6].map(item => (
              <BlogSkeleton key={item} />
            ))}
          </div>
        ) : (
          <AnimatePresence>
            {filteredBlogs.length === 0 ? (
              <div className="text-center py-16 text-slate-400">
                No blogs found matching your criteria.
              </div>
            ) : (
              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredBlogs.map((blog, index) => (
                  <motion.div
                    key={blog.id}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 20 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    className="bg-slate-800 rounded-2xl overflow-hidden shadow-lg hover:shadow-2xl transition-all group"
                  >
                    <div 
                      className="relative aspect-video overflow-hidden cursor-pointer"
                      onClick={() => handleReadMore(blog.id)}
                    >
                      <img
                        src={blog.media}
                        alt={blog.title}
                        loading="lazy"
                        className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent">
                        <span className="absolute top-4 right-4 bg-emerald-500 text-white px-3 py-1 rounded-full text-xs">
                          {blog.category}
                        </span>
                      </div>
                    </div>

                    <div className="p-6">
                      <div className="flex items-center mb-4 text-slate-400">
                        <User className="w-5 h-5 mr-2" />
                        <span className="text-sm">{blog.author}</span>
                        <span className="mx-2">•</span>
                        <Calendar className="w-5 h-5 mr-2" />
                        <span className="text-sm">
                          {new Date(blog.date).toLocaleDateString()}
                        </span>
                      </div>

                      <h2 className="text-xl font-bold mb-3 text-white group-hover:text-emerald-400 transition-colors">
                        {blog.title}
                      </h2>

                      <div className="mt-4 text-slate-300">
                        <p className={`mb-4 transition-all ${expandedBlog === blog.id ? 'line-clamp-none' : 'line-clamp-3'}`}>
                          {blog.content}
                        </p>
                        
                        <div className="flex justify-between items-center">
                          <button 
                            onClick={(e) => toggleExpand(blog.id, e)}
                            className="text-emerald-400 hover:text-emerald-300 flex items-center text-sm"
                          >
                            {expandedBlog === blog.id ? (
                              <>Show Less <ChevronUp className="ml-1 w-4 h-4" /></>
                            ) : (
                              <>Preview More <ChevronDown className="ml-1 w-4 h-4" /></>
                            )}
                          </button>
                          
                          <button 
                            onClick={() => handleReadMore(blog.id)}
                            className="flex items-center text-emerald-400 hover:text-emerald-300 group"
                          >
                            Read Full Article
                            <ArrowRight className="ml-2 group-hover:translate-x-1 transition" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};

export default Blogs;