import React from "react";
import { Helmet } from "react-helmet-async";
import Banner from "../components/Banner";
import HomeProducts from "../components/HomeProducts";
import About from "../components/About";
import Services from "../components/Services";
import Details from "../components/Details";
import Testimonials from "../components/Testimonials";
import Contact from "../components/Contact";
import HomeBlogs from "../components/HomeBlogs";

const GlowingOrb = () => (
  <div className="absolute rounded-full blur-3xl opacity-30 animate-float"
       style={{
         width: Math.random() * 300 + 200 + 'px',
         height: Math.random() * 300 + 200 + 'px',
         left: Math.random() * 100 + '%',
         top: Math.random() * 100 + '%',
         background: `radial-gradient(circle at center, 
           ${['#4F46E5', '#7C3AED', '#2563EB', '#0EA5E9'][Math.floor(Math.random() * 4)]}, 
           transparent)`,
         animationDelay: Math.random() * 5 + 's',
         animationDuration: Math.random() * 10 + 15 + 's'
       }}>
  </div>
);

const HomePage: React.FC = () => {
  return (
    <div className="relative overflow-x-hidden min-h-screen">
      {/* SEO Optimization */}
      <Helmet>
        <title>Treaz - Innovative Branding on Water Bottles</title>
        <meta name="description" content="Treaz helps brands advertise on water bottles, turning an essential product into a marketing tool." />
        <meta name="keywords" content="Treaz, water bottle branding, advertising, marketing, innovative branding" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Treaz Team" />

        {/* Open Graph for Social Media */}
        <meta property="og:title" content="Treaz - Unique Branding on Water Bottles" />
        <meta property="og:description" content="Turn everyday essentials into powerful branding tools with Treaz!" />
        <meta property="og:image" content="https://treaz.in/banner.jpg" />
        <meta property="og:url" content="https://treaz.in" />
        <meta property="og:type" content="website" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Treaz - Transforming Water Bottles into Advertisements" />
        <meta name="twitter:description" content="Treaz provides branding solutions by advertising on packaged drinking water bottles." />
        <meta name="twitter:image" content="https://treaz.in/banner.jpg" />

        {/* Canonical URL to avoid duplicate content issues */}
        <link rel="canonical" href="https://treaz.in" />

        {/* Structured Data for SEO */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Treaz",
            "url": "https://treaz.com",
            "logo": "https://treaz.com/logo.jpg",
            "sameAs": [
              "https://facebook.com/treaz",
              "https://twitter.com/treaz.india",
              "https://linkedin.com/company/treaz"
            ]
          })}
        </script>
      </Helmet>

      <div className="fixed inset-0 bg-gradient-to-br from-gray-900 via-black to-gray-900">
        <div className="absolute inset-0 bg-gradient-to-t from-indigo-900/20 via-purple-900/20 to-blue-900/20 animate-gradient"></div>
        <div className="absolute inset-0 overflow-hidden">
          {[...Array(8)].map((_, i) => (
            <GlowingOrb key={i} />
          ))}
        </div>
        <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/30 via-transparent to-transparent"></div>
        <div className="absolute inset-0 opacity-[0.015] bg-noise"></div>
      </div>

      <div className="relative z-10">
        <section id="home" className="pt-14 backdrop-blur-sm bg-black/20">
          <Banner />
        </section>
        <section id="products" className="px-4 md:px-0 pt-6 backdrop-blur-sm bg-black/20">
          <HomeProducts />
        </section>
        <section id="services" className="px-4 md:px-0 pt-8 backdrop-blur-sm bg-black/20">
          <Services />
        </section>
        <section id="blogs" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20">
          <HomeBlogs />
        </section>
        <section id="testimonials" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20">
          <Testimonials />
        </section>
        <section id="details" className="px-4 md:px-0 pt-16 backdrop-blur-sm bg-black/20">
          <Details />
        </section>
      </div>
    </div>
  );
};

export default HomePage;
