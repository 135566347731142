import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./components/About";
import AllReviews from "./pages/AllReviews";
import WriteReview from "./pages/WriteReview";
import Terms from "./components/Terms";
import Contact from "./components/Contact";
import { Analytics } from "@vercel/analytics/react";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="flex flex-col min-h-screen">
          <Analytics />
          <Header />
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/products" element={<Products />} />
              <Route path="/all-reviews" element={<AllReviews />} />
              <Route path="/write-review" element={<WriteReview />} />
              <Route path="/about" element={<About />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog" element={<Blogs />} />
              <Route path="/blog/:id" element={<BlogDetail />} />
              <Route path="*" element={<Contact />} />

            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
