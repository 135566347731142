import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  ChevronLeft, 
  Share2, 
  Heart 
} from 'lucide-react';

interface BlogPost {
  id: number;
  title: string;
  author: string;
  date: string;
  media: string;
  content: string;
  likes?: number;
}

interface ExploreBlog {
  id: number;
  title: string;
  author: string;
  media: string;
  date: string;
}

const BlogDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [blog, setBlog] = useState<BlogPost | null>(null);
  const [explorePosts, setExplorePosts] = useState<ExploreBlog[]>([]);
  const [loading, setLoading] = useState(true);
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const fetchBlogDetails = async () => {
      try {
        // Fetch individual blog post
        const postResponse = await fetch(`https://api.treaz.in/articles/${id}`);
        const postData = await postResponse.json();
        setBlog(postData);

        // Fetch all articles for explore section
        const exploreResponse = await fetch('https://api.treaz.in/articles/all');
        const exploreData = await exploreResponse.json();
        
        // Filter out current blog and limit to 4 explore posts
        const filteredExplorePosts = exploreData
          .filter((post: ExploreBlog) => post.id !== Number(id))
          .slice(0, 4);
        
        setExplorePosts(filteredExplorePosts);
      } catch (err) {
        console.error('Fetch error', err);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [id]);

  const handleLike = () => {
    setLiked(!liked);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: blog?.title,
        url: window.location.href
      });
    }
  };

  const handleExploreClick = (exploreId: number) => {
    navigate(`/blog/${exploreId}`);
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-slate-900 to-slate-800 flex items-center justify-center">
        <div className="animate-pulse space-y-4 w-full max-w-md">
          <div className="h-64 bg-slate-700 rounded-xl"></div>
        </div>
      </div>
    );
  }

  if (!blog) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 to-slate-800 text-white">
      <div className="container mx-auto px-4 py-12 max-w-6xl">
        {/* Navigation & Interactions */}
        <div className="flex justify-between items-center mb-8">
          <button 
            onClick={() => navigate(-1)}
            className="group flex items-center text-emerald-400 hover:text-emerald-300"
          >
            <ChevronLeft className="mr-2 group-hover:-translate-x-1 transition" />
            Back
          </button>
          
          <div className="flex items-center space-x-4">
            <button 
              onClick={handleLike}
              className={`flex items-center space-x-2 ${liked ? 'text-red-500' : 'text-slate-400'}`}
            >
              <Heart className={`${liked ? 'fill-current' : ''}`} />
              <span>{blog.likes || 0}</span>
            </button>
            <button 
              onClick={handleShare}
              className="text-slate-400 hover:text-white"
            >
              <Share2 />
            </button>
          </div>
        </div>

        {/* Blog Content */}
        <div className="grid md:grid-cols-3 gap-8">
          {/* Main Blog Post */}
          <div className="md:col-span-2 bg-slate-800 rounded-2xl overflow-hidden shadow-2xl">
            <div className="relative aspect-video">
              <img 
                src={blog.media} 
                alt={blog.title} 
                className="absolute inset-0 w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
            </div>

            <div className="p-6 md:p-12">
              <h1 className="text-3xl md:text-4xl font-bold mb-4">{blog.title}</h1>
              
              <div className="flex items-center space-x-4 text-slate-300 mb-6">
                <span>{blog.author}</span>
                <span className="text-sm">
                  {new Date(blog.date).toLocaleDateString()}
                </span>
              </div>

              <div className="prose prose-invert max-w-none space-y-4">
                {blog.content.split('\n').map((paragraph, index) => (
                  <p key={index} className="text-slate-300">{paragraph}</p>
                ))}
              </div>
            </div>
          </div>

          {/* Explore More Section */}
          <div className="space-y-6">
            <h2 className="text-2xl font-bold mb-4">Explore More</h2>
            {explorePosts.map((post) => (
              <div 
                key={post.id} 
                onClick={() => handleExploreClick(post.id)}
                className="bg-slate-800 rounded-xl overflow-hidden cursor-pointer hover:scale-105 transition-transform"
              >
                <div className="relative aspect-video">
                  <img 
                    src={post.media} 
                    alt={post.title} 
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-4">
                  <h3 className="font-semibold text-lg mb-2 line-clamp-2">{post.title}</h3>
                  <div className="text-sm text-slate-400 flex justify-between">
                    <span>{post.author}</span>
                    <span>{new Date(post.date).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;